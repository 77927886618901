<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Edit Object</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                v-model="form_data.page_id"
                :error-messages="form_error.page_id"
                :items="pages"
                v-on:change="getPositionList(form_data.page_id)"
                item-text="display_name"
                item-value="page_id"
                label="Select Page"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                v-model="form_data.position_id"
                :error-messages="form_error.position_id"
                :items="positions"
                item-text="name"
                item-value="page_position_id"
                label="Object Position"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
              sm="12"
              class="py-0"
              v-if="form_data.upload_url"
            >
              <v-img
                :src="form_data.upload_url"
                width="30%"
                v-if="form_data.type == 'IMAGE'"
              ></v-img>
              <video
                :src="form_data.upload_url"
                v-if="form_data.type == 'VIDEO'"
                controls
                style="max-width: 100%"
              ></video>
              <iframe
                v-if="form_data.type == 'PDF'"
                :src="form_data.upload_url + '#view=fitH&toolbar=0'"
                width="100%"
                height="280px"
              />
            </v-col>
            <v-col cols="11" md="11" sm="11" class="py-2">
              <v-file-input
                v-model="form_data.object_file"
                :error-messages="form_error.object_file"
                accept="image/*, video/*, application/pdf"
                placeholder="Select File"
                prepend-icon="mdi-file"
                outlined
                dense
              ></v-file-input>
            </v-col>
            <v-col cols="1" md="1" sm="1" class="py-2">
              <v-btn
                v-if="form_data.path"
                color="red"
                icon
                dark
                @click="dialog = true"
              >
                <v-icon>mdi mdi-delete-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.link"
                dense
                label="Link"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4"
            color="secondary"
            @click="upadteObject"
            :loading="loading"
            :disabled="loading"
            >Update</v-btn
          >
        </form>
      </v-card-text>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-text class="pt-5"> Are you sure delete image ? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey darken-4"
              text
              @click="
                dialog = false;
                object_id = '';
              "
              >Cancel</v-btn
            >
            <v-btn color="red darken-4" text @click="deleteItem">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/object">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "EditObject",
  data() {
    return {
      pages: [],
      positions: [],
      form_data: {},
      form_error: {},
      loading: false,
      data: {},
      dialog: false,
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getObjectDetails() {
      this.loading = true;
      let _self = this;
      this.$axios
        .get(`admin/object/details?object_id=` + _self.$route.params.id)
        .then((res) => {
          _self.form_error = {};
          if (res.status) {
            let data = res.data.data;
            for (let key in data) {
              _self.form_data[key] = data[key];
            }
            _self.getPositionList(_self.form_data.page_id);
          } else {
            let errors = res.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          console.log(_self.form_data);
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getPageList() {
      let _self = this;
      this.$axios
        .get(`admin/page/list`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.pages = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getPositionList(page_id) {
      let _self = this;
      this.$axios
        .get(`admin/position/list?page_id=` + page_id)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.positions = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    deleteItem() {
      let _self = this;
      _self.loading = "secondary";
      this.$axios
        .post("/admin/object/deleteImage", {
          object_id: _self.$route.params.id,
        })
        .then((res) => {
          if (res.status) {
            _self.dialog = false;
            _self.$router.push("/object");
            _self.setAlert({
              show: true,
              variant: "success",
              message: res.data.message,
              dismissCountDown: 5000,
            });
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
    upadteObject() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      if (this.form_data.object_file) {
        formData.append("object_file", this.form_data.object_file);
      }
      this.$axios
        .post(`admin/object/edit`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/object");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getPageList();
    this.getObjectDetails();
  },
};
</script>
